<template>
  <div
    v-click-outside="hide"
    class="task-settings"
    @click="show()"
  >
    <IconSettings />
    <div v-if="dropdown" class="dropdown">
      <div class="dropdown__title">{{ $t('Display settings') }}</div>

      <!-- 1. Информация о досках -->
      <div
        v-if="showBoardInfoToggle"
        class="option"
        @click="toggleOption('showBoardInfo')"
      >
        <div class="name">{{ $t('Show board info') }}</div>
        <SwitchUI v-model="userCalendarSettings.showBoardInfo" :inline="true" />
      </div>

      <!-- 2. Показывать выполненные задачи -->
      <div
        class="option"
        @click="toggleOption('showCompletedTask')"
      >
        <div class="name">{{ $t('Show completed tasks') }}</div>
        <SwitchUI v-model="userCalendarSettings.showCompletedTask" :inline="true" />
      </div>

      <!-- 3. Показывать подзадачи -->
      <div
        v-if="showSubTasksToggle"
        class="option"
        @click="toggleOption('showSubTasks')"
      >
        <div class="name">{{ $t('Show subtasks') }}</div>
        <SwitchUI v-model="userCalendarSettings.showSubTasks" :inline="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SwitchUI from '@/components/ui/SwitchUI';
import IconSettings from '@/assets/icons/settings2.svg';

export default {
  name: 'VTaskSettings',
  components: {
    SwitchUI,
    IconSettings,
  },

  props: {
    showBoardInfoToggle: {
      type: Boolean,
      default: false,
    },
    showSubTasksToggle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dropdown: false,
  }),
  computed: {
    ...mapState('user', [
      'user',
    ]),

    userCalendarSettings() {
      return this.user.calendarSettings;
    },
  },

  methods: {
    ...mapActions('user', [
      'updateCalendarSettings',
    ]),

    async toggleOption(name) {
      let data = {};
      data[name] = !this.userCalendarSettings[name];
      await this.updateCalendarSettings(data);

      if (name === 'showCompletedTask') {
        this.$emit('showCompletedTasksToggled');
      }
    },
    show() {
      this.dropdown = true;
    },
    hide() {
      this.dropdown = false;
    },
  },

  i18n: {
    messages: {
      'en': {
        'Display settings': 'Display settings',
        'Show board info': 'Show board info',
        'Show completed tasks': 'Show completed tasks',
        'Show subtasks': 'Show subtasks',
      },
      'ru': {
        'Display settings': 'Настройки отображения',
        'Show board info': 'Показывать информацию о досках',
        'Show completed tasks': 'Показывать выполненные задачи',
        'Show subtasks': 'Показывать подзадачи',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-theme {
  .task-settings {
    border-left: 1px solid $darkThemeBorderMiddle;

    svg {
      path {
        &:nth-child(1) {
          fill: $darkThemeIconLightGrey;
        }

        &:nth-child(2) {
          fill: $darkThemeIconGrey;
        }
      }
    }

    .dropdown {
      background: $darkThemeBgTwo;
      box-shadow: 0 6px 13px 0 rgba(25, 25, 25, 0.48);

      .option {
        &:hover {
          background: darken($darkThemeBgTwo, 2%);
        }
      }
    }
  }
}

.task-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 60px;
  z-index: 5;
  height: 100%;
  border-left: 1px solid $borderColor;

  svg {
    width: 21px;
    height: 21px;
    display: block;
  }

  .dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 15px;
    width: 335px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 10px 18px 0 rgba(48, 48, 48, 0.1);
    padding-bottom: 10px;

    &__title {
      padding: 20px;
      padding-bottom: 10px;
      font-weight: 600;
      font-size: 14px;
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      font-size: 14px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background: darken(#fff, 1%);
      }
    }
  }
}
</style>
